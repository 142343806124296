<template>
  <div class="app-container">
    <div style="display: flex;justify-content: space-between;">
      <div>
        <el-input placeholder="请输入客户名称或合同（订单号）" v-model="listQuery.infoFilter" style="width: 190px;margin-left:5px" size="small" @keyup.enter="getList" clearable/>
        <el-button
          type="primary"
          @click="getList"
          size="small"
          style="margin-right: 5px"
          icon="Search"
          >搜索</el-button
        >
        <search @success="getList" @cancel="cancel" name="高级筛选">
          <el-form
            style="width: 400px;"
            label-width="110px"
          >
            <el-form-item label="创建时间："> 
              <el-date-picker
                style="width:110px;"
                size="small"
                v-model="listQuery.createdAtBegin"
                type="date"
                placeholder="起始日期"
                clearable
                value-format="YYYY-MM-DD"
              /> &nbsp;-&nbsp;
              <el-date-picker
                style="width:110px;"
                size="small"
                v-model="listQuery.createdAtEnd"
                type="date"
                placeholder="终止日期"
                clearable
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
            <el-form-item label="业务类型："> 
              <cascader-common
                v-model:modelValue="listQuery.businessTypeId"
                placeholder="请选择业务类型"
                :options="contractTypeOptions"
                :casProps="casProps"
                style="width: 234px"
              />
            </el-form-item>
            <el-form-item label="任务负责人："> 
              <selectuser code="addCustomer" v-model:userId="listQuery.followUserId" :multiples="false" style="width: 234px;margin: 0;" ></selectuser>
            </el-form-item>
            <el-form-item label="步骤办理人："> 
              <selectuser code="addCustomer" v-model:userId="listQuery.followUserId" :multiples="false" style="width: 234px;margin: 0;" ></selectuser>
            </el-form-item>
            <el-form-item label="步骤名称："> 
              <el-input placeholder="" v-model="listQuery.infoFilter" style="width: 234px;margin:0" size="small" clearable/>
            </el-form-item>
            <el-form-item label="办理状态"> 
              <el-select
                v-model="listQuery.followStage"
                placeholder="请选择"
                style="width: 234px; margin: 0"
                filterable
                size="small"
              >
                <el-option
                  v-for="item in handleStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div>
        <el-button
          type="primary"
          @click="addTask"
          size="small"
          icon="Plus"
          >新增</el-button
        >
        <el-button
          type="primary"
          @click="endTask('finish')"
          size="small"
          >完成</el-button
        >
        <el-button
          type="primary"
          @click="endTask('end')"
          size="small"
          >终止</el-button
        >
        <el-button
          type="primary"
          @click="daochu"
          size="small"
          >导出</el-button
        >
      </div>
    </div>
    <!-- 列表 -->
    <div>
      <el-table
        :data="taskList"
        style="width: 100%; margin-top: 10px"
        border
        :height="contentStyleObj"
        @selection-change="handleSelectClue"
        v-loading="listLoading"
        stripe
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" width="55" align="center" fixed />
        <el-table-column
          prop="contacter"
          label="业务大类"
          min-width="100"
          align="left"
        />
        <el-table-column
          prop="link"
          label="业务类型"
          min-width="120"
          align="left"
        />
        <el-table-column
          prop="contactsNa"
          label="办理流程"
          min-width="100"
          align="left"
        />
        <el-table-column
          prop="cont"
          label="备注"
          min-width="80"
          align="left"
        />
        <el-table-column
          prop="followUserName"
          label="录入人"
          min-width="100"
          align="left"
        />
        <el-table-column
          label="操作"
          align="center"
          :width="activeName == 'no' ? 230 : 150"
          fixed="right"
        >
          <template #default="scope">
            <!-- <el-button
              v-if="activeName == 'no'"
              type="text"
              size="small"
              @click="toProtect(scope.row,'no')"
              style="margin: 0;padding: 5px 2px"
              icon="Opportunity"
            >保护</el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 新增 -->
    <taskform ref="taskform" :title="titleDrawer" @success="getList"></taskform>
  </div>
</template>

<script>
import selectBusiness from "../../crm/components/selectBusiness.vue";
import { contractTypeList } from "@/api/newCrm.js";
import { HANDL_STATUE } from "@/utils/commonData";
import taskform from "./components/taskForm.vue";

export default {
  name: '',
  components: {
    selectBusiness,
    taskform
  },
  props: {},
  data () {
    return {
      listQuery: {
        page: 1,
        size: 10,
        name: ''
      },
      taskList: [],
      total: 0,
      options: [],
      categoryList: [],
      form: {
        server: []
      },
      addDrawer: false,
      taskForm: {},
      casProps: {
        expandTrigger: "hover",
        label: "name",
        value: "id",
      },
      contractTypeOptions: [],//业务类型
      ids: [],
      select: [],
      handleStatusList: HANDL_STATUE,//办理状态
      titleDrawer:'',//抽屉title
    }
  },
  computed: {},
  watch: {},
  created () {
    this.contentStyleObj = this.$getHeight(318);
  },
  mounted () {},
  methods: {}
}
</script>

<style scoped lang="less"></style>
